import axios from '@axios';
import qs from 'qs';

export function login(data) {
    let query = qs.stringify({
        populate: ['picture', 'entity'],
    });
    return axios.post('/auth/local/?' + query, data);
}

export function tokenAuth() {
    return axios.get('/auth');
}
