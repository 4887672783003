import { tokenAuth } from '@/api/auth';

export default {
    methods: {
        onLoginSuccess(data) {
            this.$store.commit('auth/setToken', data.jwt);
            this.$store.commit('auth/setUser', data.user);
            //this.router.push({ name: 'Organization' });
        },
    },
    authWithToken() {
        tokenAuth()
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    this.$store.dispatch('auth/authenticateUser', response, { root: true });
                }
                this.setHasVerifiedToken(true);
            })
            .catch((error) => {
                console.log(error);
                this.$store.commit('auth/removeToken');
                this.$router.push('/');
                this.setHasVerifiedToken(true);
            });
    },
};
